import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import { SiGoogleforms } from "react-icons/si";
import { Chat_API_URL } from "../api/ChatApi";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function Formdata({ id, data }) {
  const phoneNumberId = localStorage.getItem("phId") || "";
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [selectedFlowId, setSelectedFlowId] = useState(""); // For flow ID
  const [flowData, setFlowData] = useState([]); // Flow data from API
  const [previewUrl, setPreviewUrl] = useState(""); // For preview URL
  const [screenName, setScreenName] = useState(""); // For screen name
  const [ctaButton, setCtaButton] = useState(""); // For cta_button
  const { setNodes } = useReactFlow();
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({}); // State for validation errors
  const [flowToken, setFlowToken] = useState("");

  // Fetch flow data when component loads
  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await fetch(`${Chat_API_URL}getPublishedFlowsForms`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ phone_number_id: phoneNumberId }),
        });
        const data = await response.json();
        console.log("flow datas ", data);
        setFlowData(data);
      } catch (error) {
        console.error("Error fetching flow data:", error);
      }
    };

    fetchFlows();
  }, []);

  // Fetch details of selected flow ID
  const handleFlowChange = async (e) => {
    const flowId = e.target.value;
    setSelectedFlowId(flowId);

    if (flowId) {
      try {
        const response = await fetch(`${Chat_API_URL}getFlowFormDetailsById`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_number_id: phoneNumberId,
            flow_id: flowId,
          }),
        });
        const result = await response.json();
        console.log("result data", result);
        setPreviewUrl(result.preview_url); // Update with the preview URL
        setScreenName(result.screen_id); // Set the screen name (optional)
      } catch (error) {
        console.error("Error fetching flow details:", error);
      }
    }
  };

  // Generate dynamic JSON payload
  const generateMessagePayload = () => {
    const payload = {
      recipient_type: "individual",
      messaging_product: "whatsapp",
      to: "PHONE_NUMBER", // Replace with actual number
      type: "interactive",
      interactive: {
        type: "flow",
        header: {
          type: "text",
          text: headerText,
        },
        body: {
          text: bodyText,
        },
        footer: {
          text: footerText,
        },
        action: {
          name: "flow",
          parameters: {
            flow_message_version: "3",
            flow_token: flowToken,
            flow_id: selectedFlowId,
            flow_action: "navigate",
            cta_button: ctaButton,
            flow_action_payload: {
              screen: screenName,
              data: {
                product_name: "name",
              },
            },
          },
        },
      },
    };
    return payload;
  };

  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "Formdata", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [headerText, bodyText, footerText, selectedFlowId, screenName, flowToken]);

  const handleNodeDelete = () => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));

    if (data.onChange) {
      data.onChange(id, "Formdata", null);
    }
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="flowMessages"
        className="ml[10px]"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <SiGoogleforms className="mt-5 text-4xl" />{" "}
        <h3 className="text-center text-[40px] mt-2 ml-4">Form data</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block mt-2 text-3xl font-semibold text-gray-700 mb-3">
          Header Text
        </label>
        <input
          type="text"
          placeholder="Enter header text"
          value={headerText}
          onChange={(e) => setHeaderText(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Body Text
        </label>
        <textarea
          placeholder="Enter body text
          max 1024 characters"
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          className="text-center rounded-2xl p-10 pt-28 text-3xl w-full"
          rows={5}
          cols={50}
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text
        </label>
        <input
          type="text"
          placeholder="Enter footer text"
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          CTA Button
        </label>
        <input
          type="text"
          placeholder="CTA Button Name"
          value={ctaButton}
          onChange={(e) => setCtaButton(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Flow Name
        </label>
        <select
          className="block w-full text-3xl p-8 rounded-2xl border border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600"
          onChange={handleFlowChange}
          value={selectedFlowId}>
          <option value="" className="text-gray-700 text-sm">
            Select Flow Name
          </option>
          {flowData.map((flow) => (
            <option
              key={flow.id}
              value={flow.id}
              className="text-gray-700 text-sm">
              {flow.name}
            </option>
          ))}
        </select>

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Flow Token
        </label>
        <input
          type="text"
          placeholder="Enter Flow Token"
          value={flowToken}
          onChange={(e) => setFlowToken(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Preview image
        </label>
        {previewUrl ? (
          <div
            style={{
              width: "100%",
              height: "900px", // Full height of 900px for the container
              overflow: "hidden",
            }}>
            <iframe
              src={previewUrl}
              title="Preview" // Changed from alt (as it's not valid for iframe)
              style={{
                width: "100%",
                height: "100%", // Set iframe height to fill the container
                objectFit: "cover",
                borderRadius: "8px",
                border: "none", // Optional: Remove border around the iframe
              }}></iframe>
          </div>
        ) : (
          <p>No preview available</p>
        )}
      </div>
    </div>
  );
}

export default Formdata;
